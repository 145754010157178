<template>
  <div class="row fw-bold mb-1">
    <div class="col-md-12 text-center">{{semesterKey%2 === 1 ? "Осенний" : "Весенний"}} семестр | {{credit}} кредитов - {{shouldChoose}} студентов | {{shouldChoose - sumCount}} не выбрали</div>
  </div>

  <div class="row fw-bold">
    <div class="col-md-5 row">
      <div class="row">
        <div class="col-12 col-md-2 fs-5 text-center">
          №
        </div>
        <div class="col-12 col-md-10 fs-5 text-center ">
          Название дисциплины
        </div>
      </div>
    </div>
    <div class="col-md-1 fs-5 text-center">
      Кредиты
    </div>
    <div class="col-md-6 fs-5 text-center">
      Действия
    </div>
  </div>
</template>

<script>
export default {
  name: "semesterHeader",
  props:{
    semesterKey: String,
    credit: Number,
    shouldChoose: String,
    sumCount: String,
  }
}
</script>

<style scoped>

</style>